import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		info:{
			wx_peopele: [],
			total_info:{
				total_people: 0,
				total_act: 0,
				total_act_year: 0,
				total_act_people: 0,
				total_wx_sign: 0,
				total_act_yesterday: 0,
				sex: {
					total_man: 0,
					total_women: 0
				},
				age: [
					0,
					0,
					0,
					0,
					0
				],
				birth: [
					0,
					0,
					0,
					0,
					0,
					0,
					0,
					0,
					0,
					0,
					0,
					0
				]
			},
			act_info:[],
			addr:[],
			name:""
		}
	},
	mutations: {
		set_info(state,info){
			state.info = info
		}
	},
	actions: {
		async get_info({ commit },path){
			const base_url = 'https://admin.kuangdayl.cn'
			fetch(`${base_url}/screen/info${path}`).then(res=>{
				res.json().then(data=>{
					commit('set_info',data)
				})
			})
		}
	},
	modules: {
	}
})
